<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
      <ValidationProvider name="Organization Type"  vid="org_type_id" rules="required|min_value:1">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_type_id"
            slot-scope="{ valid, errors }"
        >
            <template v-slot:label>
            {{$t('elearning_config.organization_type')}} <span class="text-danger">*</span>
            </template>
            <b-form-select
            plain
            v-model="formData.org_type_id"
            :options="orgTypeList"
            id="org_type_id"
            :state="errors[0] ? false : (valid ? true : null)"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Organization" v-if="formData.org_type_id === 1" vid="org_id" rules="required|min_value:1">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_id"
            slot-scope="{ valid, errors }"
        >
            <template v-slot:label>
            {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
            </template>
            <b-form-select
            plain
            v-model="formData.org_id"
            :options="componentOrgList"
            @input="orgData(formData.org_id)"
            id="org_id"
            :state="errors[0] ? false : (valid ? true : null)"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Organization Name (En)" v-if="formData.org_type_id === 2" vid="org_name_en" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_name_en"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('globalTrans.org_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="org_name_en"
            v-model="formData.org_name_en"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Organization Name (Bn)" v-if="formData.org_type_id === 2" vid="org_name_bn" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_name_bn"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('globalTrans.org_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="org_name_bn"
            v-model="formData.org_name_bn"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Organization Address (En)" v-if="formData.org_type_id === 2" vid="org_address_en" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_address_en"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('globalTrans.org_address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="org_address_en"
            v-model="formData.org_address_en"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Organization Address (Bn)" v-if="formData.org_type_id === 2" vid="org_address_bn" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_address_bn"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('globalTrans.org_address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="org_address_bn"
            v-model="formData.org_address_bn"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <div class="row">
      <div class="col-sm-3"></div>
      <div class="col text-right">
          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
      </div>
      </div>
    </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedOrganizationStore, nominatedOrganizationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.formData = tmp
    }
    // this.getOrganizationlist()
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_type_id: 0,
        org_id: 0,
        org_name_en: '',
        org_name_bn: '',
        org_address_en: '',
        org_address_bn: ''
      },
      orgType: [
        {
          value: 1,
          text: 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
    }
  },
  computed: {
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
    },
    componentOrgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${nominatedOrganizationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, nominatedOrganizationStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    orgData (orgId) {
      const orgList = this.$store.state.CommonService.commonObj.componentOrgList.find(item => item.value === parseInt(orgId))
      this.formData.org_name_en = orgList.text_en
      this.formData.org_name_bn = orgList.text_bn
      this.formData.org_address_en = orgList.address
      this.formData.org_address_bn = orgList.address_bn
    }
  }
}
</script>
