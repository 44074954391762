<template>
  <div class="inner-section">
     <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_config.nominated_organization') }}</h4> </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="4" md="4" sm="12" xs="12">
            <b-form-group
              :label="$t('elearning_config.organization_type')"
              label-for="org_type_id"
            >
              <b-form-select
                plain
                v-model="search.org_type_id"
                :options="orgTypeList"
                id="org_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="4" sm="12" xs="12">
            <b-form-group
              :label="$t('globalTrans.org_name')"
              label-for="org_name"
            >
              <b-form-input
                id="org_name"
                v-model="search.org_name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_config.nominated_organization') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <select-column-check :labelData="labelData" :search="search" :columns="columns" />
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>
                <template v-slot:cell(schedule_setup)="data">
                  <span class="capitalize">{{ data.item.schedule_setup }}</span>
                </template>
                <template v-slot:cell(order)="data">
                  {{ $n(data.item.order) }}
                </template>
                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                </template>
                <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                    <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                    </b-button>
                </template>
              </b-table>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                v-model="pagination.currentPage"
                :perPage="search.limit"
                :total-rows="pagination.totalRows"
                @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </body-card>
    <b-modal id="modal-4" size="lg" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </div>
</template>
<script>

import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedOrganizationList, nominatedOrganizationToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      sortBy: '',
      sortDirection: '',
      sortDesc: '',
      search: {
        org_type_id: 0,
        org_name: '',
        limit: 20
      },
      orgType: [
        {
          value: 1,
          text: 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_config.organization_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'globalTrans.org_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'globalTrans.org_address', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '30%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 6, thStyle: { width: '10%' } }
      ]
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    orgTypeList: function () {
      return this.orgType
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.nominated_organization') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.nominated_organization') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_type_name_bn' },
          { key: 'org_name_bn' },
          { key: 'org_address_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_type_name' },
          { key: 'org_name_en' },
          { key: 'org_address_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(12, trainingElearningServiceBaseUrl, nominatedOrganizationToggle, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, nominatedOrganizationList, params).then(response => {
          if (response.success) {
            // this.$store.dispatch('setList', response.data.data)
            this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const orgList = this.orgType
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_type_id)
        const orgData = { org_type_name: tmpData.text_en, org_type_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
